import { assertEnv } from "@bumblebee/common/utils";

// WARNING! Do not add any sensitive values here. This file is included in the client bundle in the browser.
const env = assertEnv({
  NEXT_PUBLIC_API_BASE_URL: process.env["NEXT_PUBLIC_API_BASE_URL"],
  NEXT_PUBLIC_AGENT_WS_URL: process.env["NEXT_PUBLIC_AGENT_WS_URL"],
  NEXT_PUBLIC_AGENT_API_URL: process.env["NEXT_PUBLIC_AGENT_API_URL"],
});

export default env;
