/// <reference types="./projection_edits.d.mts" />
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $blueprint from "../../json_blueprint/json/blueprint.mjs";
import * as $api from "../api/projection_edits.mjs";
import { AddDomainElement, AddFilter, AddMeasure } from "../api/projection_edits.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError } from "../gleam.mjs";
import * as $buzz_model from "../types/buzz_model.mjs";
import * as $projection from "../types/projection.mjs";
import { DomainError, FilterError, MeasureError, all_aggregations } from "../types/projection.mjs";

export class SelectAggregationFromList extends $CustomType {
  constructor(edit, aggregation) {
    super();
    this.edit = edit;
    this.aggregation = aggregation;
  }
}

export class FullyEvaluated extends $CustomType {
  constructor(projection) {
    super();
    this.projection = projection;
  }
}

export class PartialEvaluatedWithError extends $CustomType {
  constructor(projection, error, remaining_edits) {
    super();
    this.projection = projection;
    this.error = error;
    this.remaining_edits = remaining_edits;
  }
}

export class PartialEvaluatedWithFollowUps extends $CustomType {
  constructor(projection, follow_ups, remaining_edits) {
    super();
    this.projection = projection;
    this.follow_ups = follow_ups;
    this.remaining_edits = remaining_edits;
  }
}

export function edit_step(proj, edit) {
  if (edit instanceof AddDomainElement) {
    let element = edit.element;
    let added_domain = (() => {
      let _pipe = $projection.from_api_domain_element(
        $projection.get_buzz_model(proj),
        element,
      );
      let _pipe$1 = $result.map_error(
        _pipe,
        (var0) => { return new $projection.UnknownDomainElement(var0); },
      );
      return $result.then$(
        _pipe$1,
        (_capture) => { return $projection.add_domain_element(proj, _capture); },
      );
    })();
    if (added_domain.isOk()) {
      let updated_projection = added_domain[0];
      return new FullyEvaluated(updated_projection);
    } else {
      let err = added_domain[0];
      return new PartialEvaluatedWithError(
        proj,
        new DomainError(err),
        toList([]),
      );
    }
  } else if (edit instanceof AddMeasure &&
  edit.expression instanceof $api.Property) {
    let property = edit.expression.property;
    let aggregation = edit.aggregation;
    let agg = (() => {
      let _pipe = aggregation;
      return $option.map(_pipe, $projection.from_api_aggregation);
    })();
    let prop = (() => {
      let _pipe = $projection.from_api_property_reference(
        $projection.get_buzz_model(proj),
        property,
      );
      let _pipe$1 = $result.map_error(
        _pipe,
        (var0) => { return new $projection.UnknownMeasureElement(var0); },
      );
      return $result.then$(
        _pipe$1,
        (_capture) => {
          return $projection.get_measure_for_property(proj, _capture, agg);
        },
      );
    })();
    if (!prop.isOk() && prop[0] instanceof $projection.MeasureNeedsAggregation) {
      return new PartialEvaluatedWithFollowUps(
        proj,
        toList([new SelectAggregationFromList(edit, all_aggregations)]),
        toList([]),
      );
    } else if (!prop.isOk()) {
      let err = prop[0];
      return new PartialEvaluatedWithError(
        proj,
        new MeasureError(err),
        toList([]),
      );
    } else {
      let measurement = prop[0];
      let $ = $projection.add_measure(proj, measurement);
      if ($.isOk()) {
        let updated_projection = $[0];
        return new FullyEvaluated(updated_projection);
      } else {
        let err = $[0];
        return new PartialEvaluatedWithError(
          proj,
          new MeasureError(err),
          toList([]),
        );
      }
    }
  } else if (edit instanceof AddFilter) {
    let condition = edit.condition;
    let converted_filter_condition = (() => {
      let _pipe = $projection.from_api_filter_condition(
        $projection.get_buzz_model(proj),
        condition,
      );
      return $result.map_error(
        _pipe,
        (err_str) => {
          {
            let _pipe$1 = err_str;
            let _pipe$2 = new $projection.UnknownDomainElement(_pipe$1);
            return new DomainError(_pipe$2);
          }
        },
      );
    })();
    if (converted_filter_condition.isOk()) {
      let filter_condition = converted_filter_condition[0];
      let $ = $projection.add_filter_condition(proj, filter_condition);
      if ($.isOk()) {
        let updated_projection = $[0];
        return new FullyEvaluated(updated_projection);
      } else {
        let err = $[0];
        return new PartialEvaluatedWithError(
          proj,
          new FilterError(err),
          toList([]),
        );
      }
    } else {
      let err = converted_filter_condition[0];
      return new PartialEvaluatedWithError(proj, err, toList([]));
    }
  } else {
    throw makeError(
      "todo",
      "types/projection_edits",
      99,
      "edit_step",
      "`todo` expression evaluated. This code has not yet been implemented.",
      {}
    )
  }
}

export function new_projection_with_edits(projection, edits) {
  return $list.fold_until(
    edits,
    new FullyEvaluated(projection),
    (maybe_eval, edit) => {
      if (maybe_eval instanceof FullyEvaluated) {
        let proj = maybe_eval.projection;
        let $ = edit_step(proj, edit);
        if ($ instanceof FullyEvaluated) {
          let updated_proj = $.projection;
          return new $list.Continue(new FullyEvaluated(updated_proj));
        } else if ($ instanceof PartialEvaluatedWithError) {
          let err = $.error;
          return new $list.Stop(new PartialEvaluatedWithError(proj, err, edits));
        } else {
          let follow_ups = $.follow_ups;
          return new $list.Stop(
            new PartialEvaluatedWithFollowUps(proj, follow_ups, edits),
          );
        }
      } else if (maybe_eval instanceof PartialEvaluatedWithError) {
        return new $list.Stop(maybe_eval);
      } else {
        return new $list.Stop(maybe_eval);
      }
    },
  );
}

export function parse_edits_and_eval_projection(edits, buzz_model) {
  let _pipe = edits;
  let _pipe$1 = ((_capture) => {
    return $blueprint.decode($api.projection_edits_decoder(), _capture);
  })(_pipe);
  let _pipe$2 = $result.map_error(_pipe$1, $string.inspect);
  return $result.map(
    _pipe$2,
    (_capture) => {
      return new_projection_with_edits(
        $projection.new_projection(buzz_model),
        _capture,
      );
    },
  );
}

export function parse_edits_and_eval_sql(edits, buzz_model) {
  let $ = parse_edits_and_eval_projection(edits, buzz_model);
  if ($.isOk() && $[0] instanceof FullyEvaluated) {
    let proj = $[0].projection;
    return new Ok($projection.to_sql(proj));
  } else if ($.isOk() && $[0] instanceof PartialEvaluatedWithError) {
    let err = $[0].error;
    return new Error("EvaluationError:" + $string.inspect(err));
  } else if ($.isOk() && $[0] instanceof PartialEvaluatedWithFollowUps) {
    let flups = $[0].follow_ups;
    return new Error(
      "Need to follow up to continue evaluation: " + $string.inspect(flups),
    );
  } else {
    let err = $[0];
    return new Error(err);
  }
}
