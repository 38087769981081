/// <reference types="./projection.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $queue from "../../gleam_stdlib/gleam/queue.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $set from "../../gleam_stdlib/gleam/set.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $schema from "../../json_blueprint/json/blueprint/schema.mjs";
import * as $api from "../api/projection_edits.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError, isEqual } from "../gleam.mjs";
import * as $buzz_model from "../types/buzz_model.mjs";
import { ManyToMany, ManyToOne, OneToMany, OneToOne, PropertyReference } from "../types/buzz_model.mjs";

export class DomainProperty extends $CustomType {
  constructor(property) {
    super();
    this.property = property;
  }
}

export class DomainTimeline extends $CustomType {
  constructor(start, end) {
    super();
    this.start = start;
    this.end = end;
  }
}

export class Domain extends $CustomType {
  constructor(domain_elements) {
    super();
    this.domain_elements = domain_elements;
  }
}

export class DomainElementAlreadyExists extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class DisjointDomainElement extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class UnknownDomainElement extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

export class Sum extends $CustomType {}

export class Min extends $CustomType {}

export class Max extends $CustomType {}

export class Avg extends $CustomType {}

export class Count extends $CustomType {}

export class MeasureOneToOne extends $CustomType {
  constructor(property) {
    super();
    this.property = property;
  }
}

export class MeasureAgg extends $CustomType {
  constructor(aggregation, property) {
    super();
    this.aggregation = aggregation;
    this.property = property;
  }
}

export class MeasureProjection extends $CustomType {
  constructor(projection, measure) {
    super();
    this.projection = projection;
    this.measure = measure;
  }
}

export class Measure extends $CustomType {
  constructor(measure_elements) {
    super();
    this.measure_elements = measure_elements;
  }
}

export class MeasureElementAlreadyExists extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class NoMeasureFound extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class MeasureHasNoRelationWithDomain extends $CustomType {
  constructor(element, domain) {
    super();
    this.element = element;
    this.domain = domain;
  }
}

export class MeasureHasUnsolvableRelationWithDomain extends $CustomType {
  constructor(element, domain) {
    super();
    this.element = element;
    this.domain = domain;
  }
}

export class MeasureNeedsAggregation extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class UnknownMeasureElement extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

export class Projection extends $CustomType {
  constructor(buzz_model, domain, filters, order_bys, measure) {
    super();
    this.buzz_model = buzz_model;
    this.domain = domain;
    this.filters = filters;
    this.order_bys = order_bys;
    this.measure = measure;
  }
}

export class DomainError extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

export class MeasureError extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

export class FilterError extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

export class FilterDomain extends $CustomType {
  constructor(domain, operator, value) {
    super();
    this.domain = domain;
    this.operator = operator;
    this.value = value;
  }
}

export class FilterMeasure extends $CustomType {
  constructor(measure, operator, value) {
    super();
    this.measure = measure;
    this.operator = operator;
    this.value = value;
  }
}

export class ConflictingFilter extends $CustomType {
  constructor(new$) {
    super();
    this.new = new$;
  }
}

export class ConflictingTypes extends $CustomType {}

export class NoAssociatedMeasureOrDomain extends $CustomType {}

export class Equal extends $CustomType {}

export class NotEqual extends $CustomType {}

export class GreaterThan extends $CustomType {}

export class LessThan extends $CustomType {}

export class In extends $CustomType {}

export class Like extends $CustomType {}

export class IntValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class StringValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class FloatValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class BoolValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Ascending extends $CustomType {}

export class Descending extends $CustomType {}

export class OrderDomainBy extends $CustomType {
  constructor(domain, order) {
    super();
    this.domain = domain;
    this.order = order;
  }
}

export class OrderMeasureBy extends $CustomType {
  constructor(measure, order) {
    super();
    this.measure = measure;
    this.order = order;
  }
}

export function new_projection(buzz_model) {
  let domain = new Domain($list.new$());
  let measure = new Measure($list.new$());
  let filters = $list.new$();
  let order_bys = $list.new$();
  return new Projection(buzz_model, domain, filters, order_bys, measure);
}

export function get_buzz_model(projection) {
  return projection.buzz_model;
}

export function update_buzz_model_projection(projection, buzz_model) {
  return projection.withFields({ buzz_model: buzz_model });
}

export function find_property_reference_by_api_reference(projection, reference) {
  let _pipe = $result.try$(
    (() => {
      let _pipe = projection.buzz_model.entities;
      return $list.find(
        _pipe,
        (entity) => { return entity.name === reference.entity_name; },
      );
    })(),
    (entity) => {
      return $result.try$(
        (() => {
          let _pipe = entity.properties;
          return $list.find(
            _pipe,
            (property) => { return property.name === reference.property_name; },
          );
        })(),
        (property) => { return new Ok(new PropertyReference(entity, property)); },
      );
    },
  );
  return $option.from_result(_pipe);
}

export function add_domain_element(projection, new_elem) {
  let already_exist = $set.contains(
    $set.from_list(projection.domain.domain_elements),
    new_elem,
  );
  let find_disjoint_element = () => {
    if (new_elem instanceof DomainTimeline) {
      return new Error(undefined);
    } else {
      let from_prop = new_elem.property;
      let _pipe = projection.domain.domain_elements;
      return $list.find(
        _pipe,
        (dom_elem) => {
          if (dom_elem instanceof DomainProperty) {
            let to_prop = dom_elem.property;
            let _pipe$1 = $buzz_model.find_all_connected_paths(
              from_prop,
              to_prop,
              projection.buzz_model,
            );
            let _pipe$2 = $list.flatten(_pipe$1);
            let _pipe$3 = $list.length(_pipe$2);
            return ((n) => { return n === 0; })(_pipe$3);
          } else {
            return true;
          }
        },
      );
    }
  };
  if (already_exist) {
    return new Error(new DomainElementAlreadyExists(new_elem));
  } else {
    let $ = find_disjoint_element();
    if ($.isOk()) {
      let disjoint_elem = $[0];
      return new Error(new DisjointDomainElement(disjoint_elem));
    } else {
      let new_domain = $list.append(
        projection.domain.domain_elements,
        toList([new_elem]),
      );
      let new_projection$1 = projection.withFields({
        domain: new Domain(new_domain)
      });
      return new Ok(new_projection$1);
    }
  }
}

function merge_relation_types(relation_type_a, relation_type_b) {
  if (relation_type_a instanceof ManyToMany) {
    return new ManyToMany();
  } else if (relation_type_b instanceof ManyToMany) {
    return new ManyToMany();
  } else if (relation_type_a instanceof OneToMany) {
    return new OneToMany();
  } else if (relation_type_b instanceof OneToMany) {
    return new OneToMany();
  } else if (relation_type_a instanceof ManyToOne) {
    return new ManyToOne();
  } else if (relation_type_b instanceof ManyToOne) {
    return new ManyToOne();
  } else {
    return new OneToOne();
  }
}

function get_relationship_for_property(property, projection) {
  let _pipe = projection.domain.domain_elements;
  let _pipe$1 = $list.flat_map(
    _pipe,
    (dom_elem) => {
      if (dom_elem instanceof DomainProperty) {
        let from_prop = dom_elem.property;
        let _pipe$1 = $buzz_model.find_all_connected_paths(
          from_prop,
          property,
          projection.buzz_model,
        );
        return $list.map(
          _pipe$1,
          (path) => {
            return $list.fold(
              path,
              new OneToOne(),
              (acc, rel) => {
                return merge_relation_types(acc, rel.relation_type);
              },
            );
          },
        );
      } else {
        return toList([]);
      }
    },
  );
  return $set.from_list(_pipe$1);
}

export function get_measure_for_property(projection, property, use_aggregation) {
  let $ = $set.is_empty($set.from_list(projection.domain.domain_elements));
  if ($) {
    let _pipe = use_aggregation;
    let _pipe$1 = $option.map(
      _pipe,
      (agg) => { return new Ok(new MeasureAgg(agg, property)); },
    );
    return $option.unwrap(
      _pipe$1,
      new Error(new MeasureNeedsAggregation(property)),
    );
  } else {
    let join_types = get_relationship_for_property(property, projection);
    let has_one_to_one = $set.contains(join_types, new OneToOne());
    let has_one_to_many = $set.contains(join_types, new OneToMany());
    let $1 = $set.size(join_types);
    if ($1 === 0) {
      return new Error(
        new MeasureHasNoRelationWithDomain(property, projection.domain),
      );
    } else if (has_one_to_one) {
      return new Ok(new MeasureOneToOne(property));
    } else if (has_one_to_many) {
      let _pipe = use_aggregation;
      let _pipe$1 = $option.map(
        _pipe,
        (agg) => { return new Ok(new MeasureAgg(agg, property)); },
      );
      return $option.unwrap(
        _pipe$1,
        new Error(new MeasureNeedsAggregation(property)),
      );
    } else {
      return new Error(
        new MeasureHasUnsolvableRelationWithDomain(property, projection.domain),
      );
    }
  }
}

export function add_measure(projection, new_elem) {
  let already_exist = $set.contains(
    $set.from_list(projection.measure.measure_elements),
    new_elem,
  );
  let property_exists = (buzz_model, property) => {
    let _pipe = buzz_model.entities;
    let _pipe$1 = $list.filter(
      _pipe,
      (entity) => { return entity.name === property.entity.name; },
    );
    let _pipe$2 = $list.length(_pipe$1);
    return ((n) => { return n === 1; })(_pipe$2);
  };
  let ensure_entity_exists = () => {
    if (new_elem instanceof MeasureOneToOne) {
      let new_property = new_elem.property;
      let $ = property_exists(projection.buzz_model, new_property);
      
      return $;
    } else if (new_elem instanceof MeasureAgg) {
      let new_property = new_elem.property;
      let $ = property_exists(projection.buzz_model, new_property);
      
      return $;
    } else {
      throw makeError(
        "todo",
        "types/projection",
        307,
        "",
        "`todo` expression evaluated. This code has not yet been implemented.",
        {}
      )
    }
  };
  if (already_exist) {
    return new Error(new MeasureElementAlreadyExists(new_elem));
  } else {
    let $ = ensure_entity_exists();
    if (!$) {
      return new Error(new NoMeasureFound(new_elem));
    } else {
      let new_measure = $list.append(
        projection.measure.measure_elements,
        toList([new_elem]),
      );
      let new_projection$1 = projection.withFields({
        measure: new Measure(new_measure)
      });
      return new Ok(new_projection$1);
    }
  }
}

function process_measures(measure, entity) {
  return $list.fold(
    measure.measure_elements,
    [entity, toList([])],
    (acc, measure) => {
      let current_entity = acc[0];
      let current_relationships = acc[1];
      let add_prop_ref = (prop_ref) => {
        let new_property = prop_ref.property;
        let updated_entity = new $buzz_model.Entity(
          current_entity.name,
          $list.append(current_entity.properties, toList([new_property])),
          "",
        );
        let relationship = new $buzz_model.Relationship(
          (updated_entity.name + "_") + prop_ref.entity.name,
          prop_ref,
          new PropertyReference(updated_entity, new_property),
          new $buzz_model.OneToOne(),
          "",
        );
        return [
          updated_entity,
          $list.append(current_relationships, toList([relationship])),
        ];
      };
      if (measure instanceof MeasureOneToOne) {
        let prop_ref = measure.property;
        return add_prop_ref(prop_ref);
      } else if (measure instanceof MeasureAgg) {
        let prop_ref = measure.property;
        return add_prop_ref(prop_ref);
      } else {
        return [entity, toList([])];
      }
    },
  );
}

function process_domains(domains, entity) {
  return $list.fold(
    domains.domain_elements,
    [entity, toList([])],
    (acc, domain) => {
      let current_entity = acc[0];
      let current_relationships = acc[1];
      if (domain instanceof DomainProperty) {
        let from_prop = domain.property;
        let updated_entity = new $buzz_model.Entity(
          current_entity.name,
          $list.append(current_entity.properties, toList([from_prop.property])),
          "",
        );
        let relationship = new $buzz_model.Relationship(
          (updated_entity.name + "_") + entity.name,
          new PropertyReference(entity, from_prop.property),
          new PropertyReference(updated_entity, from_prop.property),
          new $buzz_model.OneToMany(),
          "",
        );
        return [
          updated_entity,
          $list.append(current_relationships, toList([relationship])),
        ];
      } else {
        return acc;
      }
    },
  );
}

export function add_entity_from_projection(model, projection, name) {
  let new_entity = new $buzz_model.Entity(name, toList([]), "");
  let measure_results = process_measures(projection.measure, new_entity);
  let domain_results = process_domains(projection.domain, measure_results[0]);
  let relationships = $list.append(measure_results[1], domain_results[1]);
  let updated_entities = $list.append(
    model.entities,
    toList([domain_results[0]]),
  );
  let updated_relationships = $list.append(model.relationships, relationships);
  let new_buzz_model = new $buzz_model.BuzzModel(
    updated_entities,
    updated_relationships,
    "",
  );
  let new_projection$1 = new Projection(
    new_buzz_model,
    projection.domain,
    projection.filters,
    projection.order_bys,
    projection.measure,
  );
  return new Ok([new_buzz_model, new_projection$1]);
}

function find_relationship_between_entities(
  model,
  from_entity_name,
  to_entity_name
) {
  return $list.find(
    model.relationships,
    (relationship) => {
      let $ = (relationship.from.entity.name === from_entity_name) && (relationship.to.entity.name === to_entity_name);
      if ($) {
        return true;
      } else {
        return false;
      }
    },
  );
}

function render_sql_table_reference(prop_ref) {
  return ("\"" + prop_ref.property.table_name) + "\"";
}

function render_sql_column_reference(prop_ref) {
  return ((("\"" + prop_ref.property.table_name) + "\".\"") + prop_ref.property.column_name) + "\"";
}

function check_conflicting_filter(projection, new_filter) {
  let _pipe = projection.filters;
  let _pipe$1 = $list.find(
    _pipe,
    (existing_filter) => {
      if (existing_filter instanceof FilterDomain &&
      new_filter instanceof FilterDomain) {
        let existing_domain = existing_filter.domain;
        let existing_operator = existing_filter.operator;
        let new_domain = new_filter.domain;
        let new_operator = new_filter.operator;
        return (isEqual(existing_domain, new_domain)) && (!isEqual(
          existing_operator,
          new_operator
        ));
      } else if (existing_filter instanceof FilterMeasure &&
      new_filter instanceof FilterMeasure) {
        let existing_measure = existing_filter.measure;
        let existing_operator = existing_filter.operator;
        let new_measure = new_filter.measure;
        let new_operator = new_filter.operator;
        return (isEqual(existing_measure, new_measure)) && (!isEqual(
          existing_operator,
          new_operator
        ));
      } else {
        return false;
      }
    },
  );
  return ((result) => {
    if (result.isOk()) {
      let conflicting_filter_condition = result[0];
      return new Error(new ConflictingFilter(conflicting_filter_condition));
    } else {
      return new Ok(undefined);
    }
  })(_pipe$1);
}

function get_domain_to_be_filtered(projection, filter) {
  if (filter instanceof FilterDomain) {
    let domain_to_filter = filter.domain;
    let _pipe = projection.domain.domain_elements;
    let _pipe$1 = $list.find(
      _pipe,
      (domain) => { return isEqual(domain, domain_to_filter); },
    );
    return ((find_result) => {
      if (find_result.isOk()) {
        let domain = find_result[0];
        if (domain instanceof DomainProperty) {
          let prop_ref = domain.property;
          return new Ok(prop_ref.property.property_type);
        } else {
          return new Error(new NoAssociatedMeasureOrDomain());
        }
      } else {
        return new Error(new NoAssociatedMeasureOrDomain());
      }
    })(_pipe$1);
  } else {
    return new Error(new NoAssociatedMeasureOrDomain());
  }
}

function get_measure_to_be_filtered(projection, filter) {
  if (filter instanceof FilterMeasure) {
    let measure_to_filter = filter.measure;
    let _pipe = projection.measure.measure_elements;
    let _pipe$1 = $list.find(
      _pipe,
      (measure) => { return isEqual(measure, measure_to_filter); },
    );
    return ((find_result) => {
      if (find_result.isOk()) {
        let measure = find_result[0];
        if (measure instanceof MeasureOneToOne) {
          let prop_ref = measure.property;
          return new Ok(prop_ref.property.property_type);
        } else if (measure instanceof MeasureAgg) {
          let prop_ref = measure.property;
          return new Ok(prop_ref.property.property_type);
        } else {
          return new Error(new NoAssociatedMeasureOrDomain());
        }
      } else {
        return new Error(new NoAssociatedMeasureOrDomain());
      }
    })(_pipe$1);
  } else {
    return new Error(new NoAssociatedMeasureOrDomain());
  }
}

function compare_filter_value_with_property_type(value, property_type) {
  if (value instanceof IntValue && property_type instanceof $buzz_model.Integer) {
    return new Ok(undefined);
  } else if (value instanceof StringValue &&
  property_type instanceof $buzz_model.String) {
    return new Ok(undefined);
  } else if (value instanceof FloatValue &&
  property_type instanceof $buzz_model.Float) {
    return new Ok(undefined);
  } else if (value instanceof BoolValue &&
  property_type instanceof $buzz_model.Boolean) {
    return new Ok(undefined);
  } else {
    return new Error(new ConflictingTypes());
  }
}

function check_conflicting_types(pt, filter) {
  if (filter instanceof FilterDomain) {
    let val = filter.value;
    return compare_filter_value_with_property_type(val, pt);
  } else {
    let val = filter.value;
    return compare_filter_value_with_property_type(val, pt);
  }
}

export function add_filter_condition(projection, new_filter) {
  let maybe_filter_err = (() => {
    let _pipe = $result.or(
      get_domain_to_be_filtered(projection, new_filter),
      get_measure_to_be_filtered(projection, new_filter),
    );
    let _pipe$1 = $result.then$(
      _pipe,
      (type_of_property_to_be_filtered) => {
        return check_conflicting_types(
          type_of_property_to_be_filtered,
          new_filter,
        );
      },
    );
    return $result.then$(
      _pipe$1,
      (_) => { return check_conflicting_filter(projection, new_filter); },
    );
  })();
  if (maybe_filter_err.isOk()) {
    return new Ok(
      new Projection(
        projection.buzz_model,
        projection.domain,
        $list.append(projection.filters, toList([new_filter])),
        projection.order_bys,
        projection.measure,
      ),
    );
  } else {
    let err = maybe_filter_err[0];
    return new Error(err);
  }
}

function is_entity_joined(joined_entites, entity_name) {
  let _pipe = joined_entites;
  let _pipe$1 = $queue.to_list(_pipe);
  let _pipe$2 = $list.filter(
    _pipe$1,
    (e_name) => { return e_name === entity_name; },
  );
  let _pipe$3 = $list.length(_pipe$2);
  return ((n) => { return n === 1; })(_pipe$3);
}

function get_select_from_prop_ref(prop_ref, agg_str) {
  if (agg_str === "") {
    return render_sql_column_reference(prop_ref);
  } else {
    let agg_str$1 = agg_str;
    return ((agg_str$1 + "(") + render_sql_column_reference(prop_ref)) + ")";
  }
}

function accumulate_prop_ref(buzz_model, acc_state, prop_ref, agg_str) {
  let entity_of_property = prop_ref.entity.name;
  let $ = is_entity_joined(acc_state[0], entity_of_property);
  if ($) {
    let updated_select_list = $list.append(
      acc_state[2],
      toList([get_select_from_prop_ref(prop_ref, agg_str)]),
    );
    return [acc_state[0], acc_state[1], updated_select_list];
  } else {
    let queue_result = $queue.pop_front(acc_state[0]);
    let left_entity_name = (() => {
      if (queue_result.isOk()) {
        let name_queue_tuple = queue_result[0];
        return name_queue_tuple[0];
      } else {
        return "ERROR";
      }
    })();
    let right_entity_name = entity_of_property;
    let find_result = find_relationship_between_entities(
      buzz_model,
      left_entity_name,
      right_entity_name,
    );
    let relationship = (() => {
      if (find_result.isOk()) {
        let relationship = find_result[0];
        return relationship;
      } else {
        return new $buzz_model.Relationship(
          "ERROR",
          prop_ref,
          prop_ref,
          new $buzz_model.OneToMany(),
          "error",
        );
      }
    })();
    let updated_joined_entities = $queue.push_front(
      acc_state[0],
      right_entity_name,
    );
    let updated_join_clause = (((((acc_state[1] + " LEFT JOIN ") + render_sql_table_reference(
      prop_ref,
    )) + " ON ") + render_sql_column_reference(relationship.from)) + " = ") + render_sql_column_reference(
      relationship.to,
    );
    let updated_select_list = $list.append(
      acc_state[2],
      toList([get_select_from_prop_ref(prop_ref, agg_str)]),
    );
    return [updated_joined_entities, updated_join_clause, updated_select_list];
  }
}

function process_select_clause_from_domains(
  projection,
  joined_entities,
  join_clauses
) {
  let select_list = $list.new$();
  let initial_state = [joined_entities, join_clauses, select_list];
  let final_state = (() => {
    let _pipe = projection.domain.domain_elements;
    return $list.fold(
      _pipe,
      initial_state,
      (acc_state, domain) => {
        if (domain instanceof DomainProperty) {
          let prop_ref = domain.property;
          return accumulate_prop_ref(
            projection.buzz_model,
            acc_state,
            prop_ref,
            "",
          );
        } else {
          return acc_state;
        }
      },
    );
  })();
  return final_state;
}

function process_select_clause_from_measures(
  projection,
  joined_entities,
  join_clauses
) {
  let select_list = $list.new$();
  let initial_state = [joined_entities, join_clauses, select_list];
  let final_state = (() => {
    let _pipe = projection.measure.measure_elements;
    return $list.fold(
      _pipe,
      initial_state,
      (acc_state, measure) => {
        if (measure instanceof MeasureAgg) {
          let agg = measure.aggregation;
          let prop_ref = measure.property;
          let agg_str = (() => {
            if (agg instanceof Sum) {
              return "SUM";
            } else if (agg instanceof Min) {
              return "MIN";
            } else if (agg instanceof Max) {
              return "MAX";
            } else if (agg instanceof Avg) {
              return "AVG";
            } else {
              return "COUNT";
            }
          })();
          return accumulate_prop_ref(
            projection.buzz_model,
            acc_state,
            prop_ref,
            agg_str,
          );
        } else if (measure instanceof MeasureOneToOne) {
          let prop_ref = measure.property;
          return accumulate_prop_ref(
            projection.buzz_model,
            acc_state,
            prop_ref,
            "",
          );
        } else {
          return acc_state;
        }
      },
    );
  })();
  return final_state;
}

function get_operator_sql_str(op) {
  if (op instanceof Equal) {
    return "=";
  } else if (op instanceof NotEqual) {
    return "<>";
  } else if (op instanceof GreaterThan) {
    return ">";
  } else if (op instanceof LessThan) {
    return "<";
  } else if (op instanceof In) {
    return "IN";
  } else {
    return "LIKE";
  }
}

function get_value_sql_str(val) {
  if (val instanceof IntValue) {
    let int = val[0];
    return $int.to_string(int);
  } else if (val instanceof StringValue) {
    let str = val[0];
    return ("'" + str) + "'";
  } else if (val instanceof FloatValue) {
    let float = val[0];
    return $float.to_string(float);
  } else {
    let bool = val[0];
    return $bool.to_string(bool);
  }
}

function get_filter_clause(prop_ref, op, val) {
  return (((render_sql_column_reference(prop_ref) + " ") + get_operator_sql_str(
    op,
  )) + " ") + get_value_sql_str(val);
}

export function to_sql(projection) {
  let joined_entites = $queue.new$();
  let join_clauses = "";
  let from_tables_result = (() => {
    let $ = (() => {
      let _pipe = projection.domain.domain_elements;
      return $list.first(_pipe);
    })();
    if ($.isOk()) {
      let first_element = $[0];
      if (first_element instanceof DomainProperty) {
        let property = first_element.property;
        let joined_entites$1 = $queue.push_front(
          joined_entites,
          property.entity.name,
        );
        return [render_sql_table_reference(property), joined_entites$1];
      } else {
        return ["ERROR", $queue.new$()];
      }
    } else {
      return ["ERROR", $queue.new$()];
    }
  })();
  let from_tables = from_tables_result[0];
  let from_tables_result$1 = (() => {
    if (from_tables === "ERROR") {
      let $ = (() => {
        let _pipe = projection.measure.measure_elements;
        return $list.first(_pipe);
      })();
      if ($.isOk()) {
        let first_element = $[0];
        if (first_element instanceof MeasureAgg) {
          let property = first_element.property;
          let joined_entites$1 = $queue.push_front(
            joined_entites,
            property.entity.name,
          );
          return [render_sql_table_reference(property), joined_entites$1];
        } else if (first_element instanceof MeasureOneToOne) {
          let property = first_element.property;
          let joined_entites$1 = $queue.push_front(
            joined_entites,
            property.entity.name,
          );
          return [render_sql_table_reference(property), joined_entites$1];
        } else {
          return ["ERROR", $queue.new$()];
        }
      } else {
        return ["ERROR", $queue.new$()];
      }
    } else {
      return from_tables_result;
    }
  })();
  let from_tables$1 = from_tables_result$1[0];
  let joined_entities = from_tables_result$1[1];
  let process_select_result = process_select_clause_from_domains(
    projection,
    joined_entities,
    join_clauses,
  );
  let joined_entities$1 = process_select_result[0];
  let join_clauses$1 = process_select_result[1];
  let select_clause_from_domains = $string.join(process_select_result[2], ", ");
  let process_select_result$1 = process_select_clause_from_measures(
    projection,
    joined_entities$1,
    join_clauses$1,
  );
  let join_clauses$2 = process_select_result$1[1];
  let select_clause_from_measures = $string.join(
    process_select_result$1[2],
    ", ",
  );
  let group_by_clause_from_domains = (() => {
    let $ = $list.length(projection.domain.domain_elements);
    if ($ === 0) {
      return "";
    } else {
      return " GROUP BY " + (() => {
        let _pipe = projection.domain.domain_elements;
        let _pipe$1 = $list.map(
          _pipe,
          (domain) => {
            if (domain instanceof DomainProperty) {
              let prop_ref = domain.property;
              return render_sql_column_reference(prop_ref);
            } else {
              return "ERROR";
            }
          },
        );
        return $string.join(_pipe$1, ", ");
      })();
    }
  })();
  let where_clause = (() => {
    let $ = projection.filters;
    if ($.hasLength(0)) {
      return "";
    } else {
      let filters = $;
      return " WHERE " + (() => {
        let _pipe = $list.map(
          filters,
          (filter) => {
            if (filter instanceof FilterDomain) {
              let domain = filter.domain;
              let op = filter.operator;
              let value = filter.value;
              if (domain instanceof DomainProperty) {
                let prop_ref = domain.property;
                return get_filter_clause(prop_ref, op, value);
              } else {
                return "ERROR";
              }
            } else {
              let measure = filter.measure;
              let op = filter.operator;
              let value = filter.value;
              if (measure instanceof MeasureOneToOne) {
                let prop_ref = measure.property;
                return get_filter_clause(prop_ref, op, value);
              } else if (measure instanceof MeasureAgg) {
                let prop_ref = measure.property;
                return get_filter_clause(prop_ref, op, value);
              } else {
                return "ERROR";
              }
            }
          },
        );
        return $string.join(_pipe, " AND ");
      })();
    }
  })();
  let order_by = (() => {
    let $ = projection.order_bys;
    if ($.hasLength(0)) {
      return "";
    } else {
      let orders = $;
      return " ORDER BY " + (() => {
        let _pipe = $list.map(
          orders,
          (order) => {
            if (order instanceof OrderDomainBy) {
              throw makeError(
                "todo",
                "types/projection",
                609,
                "",
                "`todo` expression evaluated. This code has not yet been implemented.",
                {}
              )
            } else {
              throw makeError(
                "todo",
                "types/projection",
                611,
                "",
                "`todo` expression evaluated. This code has not yet been implemented.",
                {}
              )
            }
          },
        );
        return $string.join(_pipe, ", ");
      })();
    }
  })();
  let select_clause = (() => {
    if (select_clause_from_measures === "" && select_clause_from_domains === "") {
      return "";
    } else if (select_clause_from_domains === "") {
      let select_clause_from_measures$1 = select_clause_from_measures;
      return select_clause_from_measures$1;
    } else if (select_clause_from_measures === "") {
      let select_clause_from_domains$1 = select_clause_from_domains;
      return select_clause_from_domains$1;
    } else {
      return (select_clause_from_domains + ", ") + select_clause_from_measures;
    }
  })();
  return (((((("SELECT " + select_clause) + " FROM ") + from_tables$1) + join_clauses$2) + where_clause) + group_by_clause_from_domains) + order_by;
}

export function get_buzz_model_subset_from_projection(projection) {
  let all_entites = (() => {
    let _pipe = toList([
      (() => {
        let _pipe = projection.domain.domain_elements;
        return $list.filter_map(
          _pipe,
          (domain) => {
            if (domain instanceof DomainProperty) {
              let prop_ref = domain.property;
              return new Ok(prop_ref.entity);
            } else {
              return new Error(undefined);
            }
          },
        );
      })(),
      (() => {
        let _pipe = projection.measure.measure_elements;
        return $list.filter_map(
          _pipe,
          (measure) => {
            if (measure instanceof MeasureOneToOne) {
              let prop_ref = measure.property;
              return new Ok(prop_ref.entity);
            } else if (measure instanceof MeasureAgg) {
              let prop_ref = measure.property;
              return new Ok(prop_ref.entity);
            } else {
              return new Error(undefined);
            }
          },
        );
      })(),
      (() => {
        let _pipe = projection.filters;
        return $list.filter_map(
          _pipe,
          (filter) => {
            if (filter instanceof FilterDomain) {
              let domain = filter.domain;
              if (domain instanceof DomainProperty) {
                let prop_ref = domain.property;
                return new Ok(prop_ref.entity);
              } else {
                return new Error(undefined);
              }
            } else {
              let measure = filter.measure;
              if (measure instanceof MeasureOneToOne) {
                let prop_ref = measure.property;
                return new Ok(prop_ref.entity);
              } else if (measure instanceof MeasureAgg) {
                let prop_ref = measure.property;
                return new Ok(prop_ref.entity);
              } else {
                return new Error(undefined);
              }
            }
          },
        );
      })(),
      (() => {
        let _pipe = projection.order_bys;
        return $list.filter_map(
          _pipe,
          (order) => {
            if (order instanceof OrderDomainBy) {
              let domain = order.domain;
              if (domain instanceof DomainProperty) {
                let prop_ref = domain.property;
                return new Ok(prop_ref.entity);
              } else {
                return new Error(undefined);
              }
            } else {
              let measure = order.measure;
              if (measure instanceof MeasureOneToOne) {
                let prop_ref = measure.property;
                return new Ok(prop_ref.entity);
              } else if (measure instanceof MeasureAgg) {
                let prop_ref = measure.property;
                return new Ok(prop_ref.entity);
              } else {
                return new Error(undefined);
              }
            }
          },
        );
      })(),
    ]);
    let _pipe$1 = $list.flatten(_pipe);
    return $list.unique(_pipe$1);
  })();
  let relationships = (() => {
    let _pipe = projection.buzz_model.relationships;
    return $list.filter(
      _pipe,
      (rel) => {
        return (() => {
          let _pipe$1 = $list.find(
            all_entites,
            (entity) => { return isEqual(entity, rel.to.entity); },
          );
          return $result.is_ok(_pipe$1);
        })() && (() => {
          let _pipe$1 = $list.find(
            all_entites,
            (entity) => { return isEqual(entity, rel.from.entity); },
          );
          return $result.is_ok(_pipe$1);
        })();
      },
    );
  })();
  return new $buzz_model.BuzzModel(
    all_entites,
    relationships,
    projection.buzz_model.description,
  );
}

export function from_api_property_reference(buzz, prop) {
  return $buzz_model.find_property_reference_by_name(
    buzz,
    prop.entity_name,
    prop.property_name,
  );
}

export function to_api_property_reference(prop_ref) {
  return new $api.PropertyReference(
    prop_ref.entity.name,
    prop_ref.property.name,
  );
}

export function from_api_domain_element(buzz, api_elem) {
  if (api_elem instanceof $api.DomainProperty) {
    let property = api_elem.property;
    let _pipe = from_api_property_reference(buzz, property);
    return $result.map(_pipe, (var0) => { return new DomainProperty(var0); });
  } else {
    let start_str = api_elem.start;
    let end_str = api_elem.end;
    return $result.try$(
      (() => {
        let _pipe = $birl.parse(start_str);
        return $result.replace_error(
          _pipe,
          "Failed to parse start time: " + start_str,
        );
      })(),
      (start) => {
        return $result.try$(
          (() => {
            let _pipe = $birl.parse(end_str);
            return $result.replace_error(
              _pipe,
              "Failed to parse end time: " + end_str,
            );
          })(),
          (end) => { return new Ok(new DomainTimeline(start, end)); },
        );
      },
    );
  }
}

export function to_api_domain_element(elem) {
  if (elem instanceof DomainProperty) {
    let property = elem.property;
    return new $api.DomainProperty(to_api_property_reference(property));
  } else {
    let start = elem.start;
    let end = elem.end;
    return new $api.DomainTimeline(
      $birl.to_iso8601(start),
      $birl.to_iso8601(end),
    );
  }
}

export function from_api_aggregation(api_agg) {
  if (api_agg instanceof $api.Sum) {
    return new Sum();
  } else if (api_agg instanceof $api.Min) {
    return new Min();
  } else if (api_agg instanceof $api.Max) {
    return new Max();
  } else if (api_agg instanceof $api.Avg) {
    return new Avg();
  } else {
    return new Count();
  }
}

export function to_api_aggregation(agg) {
  if (agg instanceof Sum) {
    return new $api.Sum();
  } else if (agg instanceof Min) {
    return new $api.Min();
  } else if (agg instanceof Max) {
    return new $api.Max();
  } else if (agg instanceof Avg) {
    return new $api.Avg();
  } else {
    return new $api.Count();
  }
}

export function from_api_measure_element(buzz, api_elem) {
  if (api_elem instanceof $api.MeasureOneToOne) {
    let property = api_elem.property;
    let _pipe = from_api_property_reference(buzz, property);
    return $result.map(_pipe, (var0) => { return new MeasureOneToOne(var0); });
  } else {
    let aggregation = api_elem.aggregation;
    let property = api_elem.property;
    let _pipe = from_api_property_reference(buzz, property);
    return $result.map(
      _pipe,
      (_capture) => {
        return new MeasureAgg(from_api_aggregation(aggregation), _capture);
      },
    );
  }
}

export function to_api_measure_element(elem) {
  if (elem instanceof MeasureOneToOne) {
    let property = elem.property;
    return new $api.MeasureOneToOne(to_api_property_reference(property));
  } else if (elem instanceof MeasureAgg) {
    let aggregation = elem.aggregation;
    let property = elem.property;
    return new $api.MeasureAgg(
      to_api_aggregation(aggregation),
      to_api_property_reference(property),
    );
  } else {
    throw makeError(
      "todo",
      "types/projection",
      1107,
      "to_api_measure_element",
      "`todo` expression evaluated. This code has not yet been implemented.",
      {}
    )
  }
}

export function from_api_operator(api_op) {
  if (api_op instanceof $api.Equal) {
    return new Equal();
  } else if (api_op instanceof $api.NotEqual) {
    return new NotEqual();
  } else if (api_op instanceof $api.GreaterThan) {
    return new GreaterThan();
  } else if (api_op instanceof $api.LessThan) {
    return new LessThan();
  } else if (api_op instanceof $api.In) {
    return new In();
  } else {
    return new Like();
  }
}

export function to_api_operator(op) {
  if (op instanceof Equal) {
    return new $api.Equal();
  } else if (op instanceof NotEqual) {
    return new $api.NotEqual();
  } else if (op instanceof GreaterThan) {
    return new $api.GreaterThan();
  } else if (op instanceof LessThan) {
    return new $api.LessThan();
  } else if (op instanceof In) {
    return new $api.In();
  } else {
    return new $api.Like();
  }
}

export function from_api_value(api_value) {
  if (api_value instanceof $api.IntValue) {
    let v = api_value[0];
    return new IntValue(v);
  } else if (api_value instanceof $api.StringValue) {
    let v = api_value[0];
    return new StringValue(v);
  } else if (api_value instanceof $api.FloatValue) {
    let v = api_value[0];
    return new FloatValue(v);
  } else {
    let v = api_value[0];
    return new BoolValue(v);
  }
}

export function to_api_value(value) {
  if (value instanceof IntValue) {
    let v = value[0];
    return new $api.IntValue(v);
  } else if (value instanceof StringValue) {
    let v = value[0];
    return new $api.StringValue(v);
  } else if (value instanceof FloatValue) {
    let v = value[0];
    return new $api.FloatValue(v);
  } else {
    let v = value[0];
    return new $api.BoolValue(v);
  }
}

export function from_api_filter_condition(buzz, api_filter) {
  if (api_filter instanceof $api.FilterDomain) {
    let domain = api_filter.domain;
    let operator = api_filter.operator;
    let value = api_filter.value;
    return $result.try$(
      from_api_domain_element(buzz, domain),
      (dom_elem) => {
        let _pipe = new FilterDomain(
          dom_elem,
          from_api_operator(operator),
          from_api_value(value),
        );
        return new Ok(_pipe);
      },
    );
  } else {
    let measure = api_filter.measure;
    let operator = api_filter.operator;
    let value = api_filter.value;
    return $result.try$(
      from_api_measure_element(buzz, measure),
      (mea_elem) => {
        let _pipe = new FilterMeasure(
          mea_elem,
          from_api_operator(operator),
          from_api_value(value),
        );
        return new Ok(_pipe);
      },
    );
  }
}

export function to_api_filter_condition(filter) {
  if (filter instanceof FilterDomain) {
    let domain = filter.domain;
    let operator = filter.operator;
    let value = filter.value;
    return new $api.FilterDomain(
      to_api_domain_element(domain),
      to_api_operator(operator),
      to_api_value(value),
    );
  } else {
    let measure = filter.measure;
    let operator = filter.operator;
    let value = filter.value;
    return new $api.FilterMeasure(
      to_api_measure_element(measure),
      to_api_operator(operator),
      to_api_value(value),
    );
  }
}

export function from_api_order(api_order) {
  if (api_order instanceof $api.Ascending) {
    return new Ascending();
  } else {
    return new Descending();
  }
}

export function to_api_order(order) {
  if (order instanceof Ascending) {
    return new $api.Ascending();
  } else {
    return new $api.Descending();
  }
}

export function from_api_order_by(buzz, api_order) {
  if (api_order instanceof $api.OrderDomainBy) {
    let domain = api_order.domain;
    let order = api_order.order;
    return $result.try$(
      from_api_domain_element(buzz, domain),
      (dom_elem) => {
        let _pipe = new OrderDomainBy(dom_elem, from_api_order(order));
        return new Ok(_pipe);
      },
    );
  } else {
    let measure = api_order.measure;
    let order = api_order.order;
    return $result.try$(
      from_api_measure_element(buzz, measure),
      (mea_elem) => {
        let _pipe = new OrderMeasureBy(mea_elem, from_api_order(order));
        return new Ok(_pipe);
      },
    );
  }
}

export function to_api_order_by(order) {
  if (order instanceof OrderDomainBy) {
    let domain = order.domain;
    let order$1 = order.order;
    return new $api.OrderDomainBy(
      to_api_domain_element(domain),
      to_api_order(order$1),
    );
  } else {
    let measure = order.measure;
    let order$1 = order.order;
    return new $api.OrderMeasureBy(
      to_api_measure_element(measure),
      to_api_order(order$1),
    );
  }
}

export const all_aggregations = /* @__PURE__ */ toList([
  /* @__PURE__ */ new Sum(),
  /* @__PURE__ */ new Min(),
  /* @__PURE__ */ new Max(),
  /* @__PURE__ */ new Avg(),
  /* @__PURE__ */ new Count(),
]);
