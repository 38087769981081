// use to copy paste to create a new context file
"use client";

import { projection_edits_encoder } from "@bumblebee/core/api/projection_edits";
import { toList } from "@bumblebee/core/gleam";
import { to_string } from "@bumblebee/core/gleam/json";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { useErrorContext } from "./ErrorContext";
import { useProjectionContext } from "./ProjectionContext";

interface ResponseRow {
  [key: string]: string;
}

interface ResponseColumn {
  name: string;
  type: string;
}

interface QueryResponse {
  columns: ResponseColumn[];
  rows: ResponseRow[];
  sql: string;
}

interface QueryContextType {
  queryResponse: QueryResponse | null;
  error: boolean;
  executeProjection: () => void;
}

// Create the context initial value
const QueryContext = createContext<QueryContextType | undefined>(undefined);

// Create a provider component
export const QueryContextProvider = ({ children }: PropsWithChildren) => {
  const [queryResponse, setQueryResponse] = useState<QueryResponse | null>(
    null,
  );

  const { projectionEdits } = useProjectionContext();
  const { error, setError } = useErrorContext();

  // Temp, during dev, just execute the projection whenever it changes
  useEffect(() => {
    if (error != null) return;
    if (projectionEdits.length === 0) return;

    const fetchResponse = async () => {
      try {
        const body = to_string(
          projection_edits_encoder(toList(projectionEdits)),
        );

        const response = await fetch("/api/query", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        });
        const data = await response.json();
        setQueryResponse(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error("Unknown error fetching initial query response"));
          console.error(error);
        }
      }
    };
    console.log("fetching response because edits changed", projectionEdits);
    fetchResponse();
  }, [projectionEdits, error, setError]);

  return (
    <QueryContext.Provider
      value={{
        queryResponse,
        error: false,
        executeProjection: () => {},
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

// Create a custom hook to use the context
export const useQueryContext = () => {
  const context = useContext(QueryContext);
  if (context === undefined) {
    throw new Error("useQueryContext must be used within a QueryContext");
  }
  return context;
};
