"use client";

import { exhaustiveCheck, fromResult } from "@bumblebee/common/utils";
import {
  AddDomainElement,
  AddFilter,
  AddMeasure,
  aggregation_to_string,
  Aggregation$,
  DomainProperty,
  DomainTimeline,
  FilterCondition$,
  ProjectionEdit$,
  Property,
  PropertyReference,
  PropertyReference$,
} from "@bumblebee/core/api/projection_edits";
import { buzz_model_from_json, BuzzModel } from "@bumblebee/core/buzz_model";
import { toList } from "@bumblebee/core/gleam";
import { Some } from "@bumblebee/core/gleam/option";
import * as string from "@bumblebee/core/gleam/string";
import { new_projection, Projection } from "@bumblebee/core/projection";
import {
  type EditFollowUp$,
  FullyEvaluated,
  new_projection_with_edits,
  PartialEvaluatedWithError,
  PartialEvaluatedWithFollowUps,
  SelectAggregationFromList,
} from "@bumblebee/core/projection_edits";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import env from "@/env";

import { useErrorContext } from "./ErrorContext";

// the fields needed to call get_measure_for_property
interface MeasureArgs {
  property: PropertyReference$;
  aggregation: Aggregation$ | undefined;
}

// Define the shape of your context
interface ProjectionContextType {
  addDomainProperties: (prs: PropertyReference$[]) => void;
  addFilter: (condition: FilterCondition$) => void;
  addMeasure: (mc: MeasureArgs) => void;
  domainProperties: DomainProperty[];
  domainTimelines: DomainTimeline[];
  filters: FilterCondition$[];
  measures: AddMeasure[];
  model: BuzzModel;
  projection: Projection;
  projectionEdits: ProjectionEdit$[];
  pullProjection: () => Promise<void>;
  removeDomainProperty: (p: DomainProperty) => void;
  removeFilter: (condition: FilterCondition$) => void;
  removeMeasure: (m: AddMeasure) => void;
  setProjectionEdits: (edits: ProjectionEdit$[]) => void;
}

// Create the context initial value
const ProjectionContext = createContext<ProjectionContextType | undefined>(
  undefined,
);

// Create a provider component
interface Props extends PropsWithChildren {
  initialBuzzModelJSON: string;
}

const prettyPrintFollowUp = (follow_up: EditFollowUp$): string => {
  switch (true) {
    case follow_up instanceof SelectAggregationFromList:
      return `Select aggregation from: ${follow_up.aggregation.toArray().map(aggregation_to_string).join(", ")}`;
    default:
      return `Unknown follow-up ${typeof follow_up}`;
  }
};

export const ProjectionContextProvider = ({
  children,
  initialBuzzModelJSON,
}: Props) => {
  /*************************
   * State
   *************************/
  // construct the buzz model from the initial JSON from the server
  const buzzModel = useMemo<BuzzModel>(
    () => fromResult(buzz_model_from_json(initialBuzzModelJSON)),
    [initialBuzzModelJSON],
  );

  // create empty base projection to calculate edits from
  const emptyProjection = useMemo<Projection>(
    () => new_projection(buzzModel),
    [buzzModel],
  );

  // keep track of the evaluated projection for use in the UI
  const [projection, setProjection] = useState<Projection>(
    new_projection(buzzModel),
  );

  // keep track of all edits to the projection, applied in order
  const [projectionEdits, setProjectionEdits] = useState<ProjectionEdit$[]>([]);

  /*************************
   * Context
   *************************/
  const { setError } = useErrorContext();

  /*************************
   * Misc
   *************************/
  // function to handle errors in projection evaludation
  const handleProjectionError = useCallback(
    (fn: () => void) => {
      try {
        fn();
        setError(null);
      } catch (e) {
        setError(
          e instanceof Error
            ? e
            : new Error("Unknown error in projection: " + e),
        );
      }
    },
    [setError],
  );

  /*************************
   * Projection Edits
   *************************/
  // re-evaluate the evaluated projection from emptyProjection + edits
  const evaluateEdits = useCallback(
    (edits: ProjectionEdit$[]) => {
      const projectionEval = new_projection_with_edits(
        emptyProjection,
        toList(edits),
      );

      switch (true) {
        case projectionEval instanceof FullyEvaluated:
          setProjection(projectionEval.projection);
          setError(null);
          break;
        case projectionEval instanceof PartialEvaluatedWithFollowUps:
          setError(
            new Error(
              `Projection not fully evaluated. Follow-ups:\n${projectionEval.follow_ups.toArray().map(prettyPrintFollowUp).join("\n ")}`,
            ),
          );
          break;
        case projectionEval instanceof PartialEvaluatedWithError:
          setError(
            new Error(
              `Projection not fully evaluated. Failed to evaluate: ${string.inspect(projectionEval.error)}`,
            ),
          );
          break;
        default:
          setError(new Error(`Projection not fully evaluated. Unknown error.`));
      }
    },
    [setError, emptyProjection],
  );

  // re-evaluate projection when edits change
  useEffect(() => {
    evaluateEdits(projectionEdits);
  }, [projectionEdits, evaluateEdits]);

  /*************************
   * Domain Elements
   *************************/
  const addDomainProperties = useCallback(
    (propertyReferences: PropertyReference$[]) => {
      const edits = propertyReferences.map((pr) => {
        return new AddDomainElement(new DomainProperty(pr));
      });
      setProjectionEdits((prev) => [...prev, ...edits]);
    },
    [],
  );

  const removeDomainProperty = useCallback((domainProperty: DomainProperty) => {
    setProjectionEdits((prev) => [
      ...prev.filter(
        (pe) =>
          !(pe instanceof AddDomainElement && pe.element === domainProperty),
      ),
    ]);
  }, []);

  const domainProperties = useMemo<DomainProperty[]>(() => {
    return projectionEdits
      .filter((pe) => pe instanceof AddDomainElement)
      .map((pe) => pe.element)
      .filter((ade) => ade instanceof DomainProperty);
  }, [projectionEdits]);

  const domainTimelines = useMemo<DomainTimeline[]>(() => {
    return projectionEdits
      .filter((pe) => pe instanceof AddDomainElement)
      .map((pe) => pe.element)
      .filter((ade) => ade instanceof DomainTimeline);
  }, [projectionEdits]);

  /*************************
   * MEASURES
   *************************/
  const addMeasure = useCallback(
    ({ property, aggregation }: MeasureArgs) => {
      handleProjectionError(() => {
        if (aggregation == null) {
          throw new Error(
            "addMeasure: Aggregation and property must be defined",
          );
        }
        const edit = new AddMeasure(
          new Property(
            new PropertyReference(property.entity_name, property.property_name),
          ),
          new Some(aggregation),
        );
        setProjectionEdits((prev) => [...prev, edit]);
      });
    },
    [handleProjectionError],
  );

  const removeMeasure = useCallback((measure: AddMeasure) => {
    setProjectionEdits((prev) => [
      ...prev.filter((pe) => !(pe instanceof AddMeasure && pe === measure)),
    ]);
  }, []);

  const measures = useMemo<AddMeasure[]>(() => {
    return projectionEdits.filter((pe) => pe instanceof AddMeasure);
  }, [projectionEdits]);

  /*************************
   * Filters
   *************************/
  const addFilter = useCallback((condition: FilterCondition$) => {
    const edit = new AddFilter(condition);
    setProjectionEdits((prev) => [...prev, edit]);
  }, []);

  const removeFilter = useCallback((condition: FilterCondition$) => {
    setProjectionEdits((prev) => [
      ...prev.filter(
        (pe) => !(pe instanceof AddFilter && pe.condition === condition),
      ),
    ]);
  }, []);

  const filters = useMemo<FilterCondition$[]>(() => {
    return projectionEdits
      .filter((pe) => pe instanceof AddFilter)
      .map((pe) => pe.condition);
  }, [projectionEdits]);

  /*************************
   * API
   *************************/
  // pull projection from API (agent handoff)
  const pullProjection = useCallback(async () => {
    handleProjectionError(async () => {
      const response = await fetch(
        `${env.NEXT_PUBLIC_API_BASE_URL}/projection`,
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const edits: ProjectionEdit$[] = await response.json();
      const editsList = toList(edits);

      const projectionEval = new_projection_with_edits(
        new_projection(buzzModel),
        editsList,
      );
      switch (true) {
        case projectionEval instanceof FullyEvaluated:
          setProjection(projectionEval.projection);
          break;
        case projectionEval instanceof PartialEvaluatedWithError:
          setError(new Error(projectionEval.error.constructor.name));
          break;
        case projectionEval instanceof PartialEvaluatedWithFollowUps:
          setProjection(projectionEval.projection);
          // TODO: handle follow ups
          break;
        default:
          exhaustiveCheck(projectionEval);
      }

      setError(null);
    });
  }, [setError, handleProjectionError, buzzModel]);

  return (
    <ProjectionContext.Provider
      value={{
        addDomainProperties,
        addFilter,
        addMeasure,
        domainProperties,
        domainTimelines,
        filters,
        measures,
        model: buzzModel,
        projection,
        projectionEdits,
        pullProjection,
        removeDomainProperty,
        removeFilter,
        removeMeasure,
        setProjectionEdits,
      }}
    >
      {children}
    </ProjectionContext.Provider>
  );
};

// Create a custom hook to use the context
export const useProjectionContext = () => {
  const context = useContext(ProjectionContext);
  if (context === undefined) {
    throw new Error(
      "useProjectionContext must be used within a ProjectionContext",
    );
  }
  return context;
};
